@charset "utf-8";
@import url("http://fonts.googleapis.com/css?family=Oswald");
@import url("http://fonts.googleapis.com/css?family=Arvo:400,700");
@import url("http://fonts.googleapis.com/css?family=Pacifico");
/*============================== 
	- Template Name: FOREVER - Responsive HTML Wedding Template
	- Author: DoubleEight
	- Version: 1.02
	- Website: www.dethemes.com
================================= */

/*---------------------- 
	Stylesheet Guide
------------------------
00. PRELOADER

01. GENERAL
			
02. NAV BAR
	02.1 Width & Color
		02.1.1 Fixed Width
		02.1.2 Fluid Width
		02.1.3 Top Bar
		02.1.4 Bottom Bar
		02.1.5 Transparent & Block Color
	02.2 Logo & Nav Position
		02.2.1 Nav Center
		02.2.2 Nav Left
		02.2.3 Nav Right
		
03. NAV MENU
	03.1 Parent Menu
		03.1.1 Transparent
		03.1.2 Additional Class For Nav Center
	03.2 Child Menu
		03.2.1 Reset Transparent Child Menu Color
		03.2.2 Child-Parent Arrow
		03.2.3 More Child
	03.3 Mobile Nav
	
04. SIDE NAV BAR
	04.1 Menuicon Bar Width & Position
		04.1.1 Fixed Width
		04.1.2 Fluid Width
		04.1.3 Menuicon-Left
		04.1.4 Menuicon-Right
	04.2 Menu Icon
		04.2.1 Standard Menu Icon
		04.2.2 Sticky Menu Icon
	04.3 Sidebar Menu
		04.3.1 Sidebar
		04.3.2 Sidebar Header
		04.3.3 Parent Menu		
		04.3.4 Child Menu
		04.3.5 Child-Parent Arrow Icon
		04.3.6 More Child Menu
		
05. LOGO
	05.1 CSS Logo
	05.2 IMG Logo

06. MAIN-SLIDER
	06.1 Main Slider Height
	06.2 Main Slider Flexslider
		06.2.1 Main Slider Flexslider Control Nav & Paging
	06.3 Main Slider Slide-Image
	06.4 Main Slider Slide-Video
	06.5 Main Slider Title
		06.5.1 Outter Wrapper 
		06.5.2 Inner Wrapper
		06.5.3 Title Wrapper
		06.5.4 Title Alignment
		06.5.6 Save The Date Title
	06.6 Each Slide Overlay
	06.7 Each Slide Caption
	06.8 Hack Flexslider Fade for Firefox & IE11
	06.9 Loop Animation
		06.9.1 Zoom
		06.9.2 Left-Right
	06.10 Video Audio Control
		
07. PAGE
	07.1 Page Layout
		07.1.1 Page Without Slider
		07.1.2 Page With Slider
		07.1.3 Page With Banner
		07.1.4 Page With Full Gallery
		07.1.5 Page With Outside Bottom Bar 
	07.2 Page Element
		07.2.1 Page Title Wrapper
		07.2.2 Section Title Wrapper
		07.2.3 Icon Title Wrapper
		07.2.4 Title-Excerpt White Box
		07.2.5 Blurb Wrapper		
		07.2.6 Align Middle Wrapper	
		07.2.7 Image & Video Divider
		07.2.8 Space & Line Divider
		07.2.9 Heart Divider
		07.2.10 Button
		07.2.11 Banner Text
		07.2.12 White Box Content Wrapper
		07.2.13 Popup Template
		07.2.14 Autocrop Image

08. ICON
	08.1 Size
	08.2 Shape
		08.2.1 Default	
		08.2.2 Circle, Rounded, Square	
	08.3 Effect Zoom 
	08.4 Effect Rotate
	08.5 Effect Square/Rounded To Circle		
			
09. PHOTO ITEM
	09.1 Image
	09.2 Layer
		09.2.1 Layer : Second Image
		09.2.2 Layer : Black Overlay 
		09.2.3 Layer : Photo Caption
		09.2.4 Layer : Icon / Button Link  
	09.3 on Hover Animation
		09.3.1 on Hover Animation Duration
		09.3.2 on Hover Animation Delay	
		09.3.3 on Hover Zoom In	
		09.3.4 on Hover Zoom In and Rotate
		09.3.5 on Hover Fade Out		

10. MASONRY GALLERY
	10.1 Three Column No Gutter
	10.2 Three Column With Gutter
	10.3 Four Column No Gutter
	10.4 Four Column With Gutter
	10.5 Five Column No Gutter
	10.6 Five Column With Gutter	
	
11. FORM
	11.1 Form Group
	11.2 Form Control
	11.3 Input Group
	11.4 Label
	11.5 Button
	11.6 Checkbox and Radio(Custom)
	11.7 Select Option
	11.8 Message
	11.9 Validation	
		
12. HOME PAGE
	12.1 Couple Section
	12.2 Location & Countdown Section
	12.3 The Wedding Section
	12.4 RSVP Section
	12.5 Footer Section
	
13. OTHER PAGE
	13.1 LOCATION PAGE
	13.2 RSVP-2 PAGE
	13.3 BRIDESMAID PAGE
	13.4 GROOMSMEN PAGE
	13.5 BLOG PAGE

14. ONEPAGE SECTION
	14.1 Slider Section		
	14.2 Couple Section	
	14.3 Our Story Section
	14.4 Where When Section
	14.5 Gallery Section
	14.6 Don't Miss It / Countdown Section
	14.7 More Events Section
	14.8 Groomsmen Section	
	14.9 Bridesmaid Section
	14.10 Gift Section	
	14.11 RSVP Section

15. PLUGIN CUSTOM
	15.1 Owl Carousel
	15.2 Magnific Popup

16. MEDIA SCREEN
	- @media screen and (max-width: 1200px)
	- @media screen and (max-width: 991px)
	- @media screen and (max-width: 991px) and (orientation : landscape)
	- @media screen and (max-width: 768px) and (orientation : landscape)
	- @media screen and (max-width: 768px)
	- @media screen and (max-width: 480px)
	- @media screen and (max-width: 320px)

*/

/*===============================================*/
/* 00. PRELOADER		 						 */
/*===============================================*/
/* See: */
/* - preloader.css */
/* - preloader-couple-name.css */
/* - preloader-default.css */
/* - pace/pace-theme-minimal.tmpl.css*/

/*===============================================*/
/* 01. GENERAL			 						 */
/*===============================================*/
body {
	font-family: "Arvo", serif;
	color: #6a6a6a;
	font-size: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Oswald", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: #333333;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	color: #f0394d;
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
	margin-top: 20px;
	margin-bottom: 20px;
}

h4,
h5,
h6 .h4,
.h5,
.h6 {
	margin-top: 10px;
	margin-bottom: 10px;
}

h1,
.h1 {
	font-size: 40px;
}

h2,
.h2 {
	font-size: 30px;
}

h3,
.h3 {
	font-size: 20px;
}

h4,
.h4 {
	font-size: 16px;
}

h5,
.h5 {
	font-size: 14px;
}

h6,
.h6 {
	font-size: 12px;
}

h1.inline,
h2.inline,
h3.inline,
h4.inline,
h5.inline,
h6.inline {
	display: inline;
	vertical-align: middle;
}

p {
	font-family: "Arvo", serif;
	font-size: 15px;
	line-height: 26px;
	margin-bottom: 25px;
}

p.lead {
	font-size: 19px;
	line-height: 28px;
	font-weight: normal;
}

a {
	text-decoration: none;
	color: #6a6a6a;
}

a:hover {
	text-decoration: none;
}

.clearboth {
	clear: both;
}

.no-padding {
	padding: 0;
}

.no-margin {
	margin: 0;
}

img.fullwidth {
	width: 100%;
}

img.with-border {
	border: 1px solid #6a6a6a;
}

/*===============================================*/
/* 02. NAV BAR  	 							 */
/*===============================================*/
#nav-header {
	z-index: 1000;
}

#nav-bar {
	position: relative;
	height: 145px;
	width: 100%;
	z-index: 1000;
}

/* ----------------------------------------------*/
/* 02.1 Width & Color 							 */
/* ----------------------------------------------*/

/* 02.1.1 Fixed-width 							 */
/* ----------------------------------------------*/
#nav-bar.fixed-width #nav-wrapper {
	position: relative;
	height: inherit;
	width: 1170px;
	margin: 0 auto;
	padding: 0 30px;
}

/* 02.1.2 Fluid-width 							 */
/* ----------------------------------------------*/
#nav-bar.fluid-width #nav-wrapper {
	position: relative;
	height: inherit;
	width: 100%;
	padding: 0 30px;
}

/* 02.1.3 Top Bar 								 */
/* ----------------------------------------------*/
#nav-bar.top-bar {
	position: absolute;
	/*background-color:transparent;*/
	margin: auto;
	left: 0;
	right: 0;
}

#nav-bar.top-bar.stick-it {
	position: fixed;
	height: 145px;
	width: 100%;
	background-color: #fff;
	z-index: 1500;
	/*-webkit-transform: translateZ(0);*/
}

/* 02.1.4 Bottom Bar 							 */
/* ----------------------------------------------*/
#nav-bar.bottom-bar.inside {
	position: absolute;
	/*background-color:transparent;*/
	bottom: 0;
}

#nav-bar.bottom-bar.outside {
	position: absolute;
	/*background-color:transparent;*/
	bottom: -145px;
}

#nav-bar.bottom-bar.stick-it {
	position: fixed;
	height: 145px;
	width: 100%;
	background-color: #fff;
	z-index: 1500;
	top: 0;
	bottom: auto;
	/*-webkit-transform: translateZ(0)*/
}

/* 02.1.5 Transparent & Block Color 			 */
/* ----------------------------------------------*/
#nav-bar.transparent {
	/*position:relative;*/
	background-color: transparent;
}

#nav-bar.block-color {
	/*position:relative; */
	background-color: #fff;
}

/*.is-sticky #nav-bar.block-color{
	-webkit-transform: translateZ(0)
}*/

/* ----------------------------------------------*/
/* 02.2 Logo & Nav Position						 */
/* ----------------------------------------------*/

.logo-wrapper {
	position: relative;
	width: 60px;
	height: 60px;
	margin-top: 40px;
}

#nav-menu {
	list-style-type: none;
	margin: 0;
	padding: 45px 0 0 0;
	font-family: "Oswald", sans-serif;
	font-size: 13px;
	z-index: 1000;
}

/* 02.2.1 Nav Center 							 */
/* ----------------------------------------------*/
#nav-bar.nav-center .logo-wrapper {
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

#nav-bar.nav-center #nav-menu {
	text-align: center;
}

/* 02.2.2 Nav Left 								 */
/* ----------------------------------------------*/
#nav-bar.nav-left .logo-wrapper {
	float: right;
}

#nav-bar.nav-left #nav-menu {
	float: left;
	text-align: left;
	width: 90%;
}

/* 02.2.3 Nav Right 							 */
/* ----------------------------------------------*/
#nav-bar.nav-right .logo-wrapper {
	float: left;
}

#nav-bar.nav-right #nav-menu {
	float: right;
	text-align: right;
	width: 90%;
}

/*===============================================*/
/* 03. NAV MENU	 	 							 */
/*===============================================*/

/* ----------------------------------------------*/
/* 03.1 Parent Menu								 */
/* ----------------------------------------------*/
#nav-menu > li {
	position: relative;
	display: inline;
}

#nav-menu > li:before {
	font-family: "fontello";
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	content: "\e9bd";
	color: #f0394d;
	text-align: center;
}

#nav-menu > li.first-child:before {
	content: "";
	margin-left: 0;
}

#nav-menu > li a {
	display: inline-block;
	padding: 18px;
	color: #666;
	text-decoration: none;
}

#nav-menu > li a:hover {
	color: #f0394d;
}

/* 03.1.1 Transparent 							 */
/* ----------------------------------------------*/
.transparent #nav-menu > li a {
	color: #fff;
}

.transparent #nav-menu > li a:hover {
	color: #f0394d;
}

.transparent.stick-it #nav-menu > li a {
	color: #666;
}

/* 03.1.2 Additional Class For Nav Center 		 */
/* ----------------------------------------------*/
.first-child-margin {
	margin-left: 20px;
}

.last-child-margin {
	margin-right: 0px;
}

.split-margin {
	margin-left: 80px;
}

/* ----------------------------------------------*/
/* 03.2 Child Menu								 */
/* ----------------------------------------------*/
#nav-menu ul {
	position: absolute;
	display: none;
	width: 200px;
	left: 0;
	list-style-type: none;
	padding: 0;
	text-align: left;
	border-top: solid 3px #f0394d;
}

#nav-menu li:hover > ul {
	display: block;
}

#nav-menu ul li {
	position: relative;
	width: 100%;
}

#nav-menu ul li a {
	display: block;
	width: 100%;
	background-color: #fff;
	border-bottom: solid thin #f4f2f2;
}

#nav-menu ul li a:hover {
	background-color: #f0394d;
	border: none;
	color: #fff;
}

/* 03.2.1 Reset Transparent Child Menu Color	 */
/* ----------------------------------------------*/
.transparent #nav-menu ul li a {
	color: #666;
}

.transparent #nav-menu ul li a:hover {
	color: #fff;
}

/* 03.2.2 Child-Parent Arrow 					 */
/* ----------------------------------------------*/
#nav-menu li > ul li a:after {
	content: ">";
	position: absolute;
	right: 15px;
}

#nav-menu li > ul li a:only-child:after {
	content: "";
}

/* 03.2.3 More Child 						  	 */
/* ----------------------------------------------*/
#nav-menu ul ul {
	top: 0;
	left: 100%;
	border: none;
}

/* ----------------------------------------------*/
/* 03.3 Mobile Nav								 */
/* ----------------------------------------------*/
#mobile-nav {
	display: none;
}

#mobile-nav,
.transparent.stick-it #mobile-nav {
	color: #666;
}

.transparent #mobile-nav {
	color: #fff;
}

/*===============================================*/
/* 04. SIDE NAV BAR		 						 */
/*===============================================*/

/* ----------------------------------------------*/
/* 04.1 Menuicon Bar Width & Position 			 */
/*-----------------------------------------------*/
#menuicon-bar {
	position: absolute;
	height: 145px;
	z-index: 1000;
	background-color: transparent;
	margin: auto;
	top: 30px;
	left: 0;
	right: 0;
	padding: 0 80px;
}

/* 04.1.1 Fixed-width */
/* ----------------------------------------------*/
#menuicon-bar.fixed-width {
	width: 1170px;
}

/* 04.1.2 Fluid-width */
/* ----------------------------------------------*/
#menuicon-bar.fluid-width {
	width: 100%;
}

/* 04.1.3 Menuicon-Left */
/* ----------------------------------------------*/
#menuicon-bar.menuicon-left .logo-outter-wrapper {
	float: right;
}

#menuicon-bar.menuicon-left #menu-icon {
	float: left;
}

/* 04.1.4 Menuicon-Right */
/* ----------------------------------------------*/
#menuicon-bar.menuicon-right .logo-outter-wrapper {
	float: left;
}

#menuicon-bar.menuicon-right #menu-icon {
	float: right;
}

/* ----------------------------------------------*/
/* 04.2 Menu Icon					 			 */
/*-----------------------------------------------*/

/* 04.2.1 Standard Menu Icon */
/* ----------------------------------------------*/
#menu-icon {
	margin-top: 50px;
	display: block;
}

/*#menu-icon a{
}*/

#menu-icon a i {
	color: #666;
	font-size: 24px;
}

#menu-icon a:hover i {
	color: #f0394d;
}

#menu-icon.white a i {
	color: #fff;
	font-size: 24px;
}

#menu-icon.white a:hover i {
	color: #f0394d;
}

/* 04.2.2 Sticky Menu Icon */
/* ----------------------------------------------*/
#sticky-menuicon {
	display: none;
	position: fixed;
	z-index: 1000;
	top: 30px;
	-webkit-transform: translateZ(0);
}

#sticky-menuicon a {
	margin-top: 12px;
	display: block;
	background-color: #fff;
	padding: 5px;
}

#sticky-menuicon a i {
	color: #666;
	font-size: 24px;
}

#sticky-menuicon a:hover {
	background-color: #f0394d;
}

#sticky-menuicon a:hover i {
	color: #fff;
}

#menuicon-bar.menuicon-right #sticky-menuicon {
	right: 50px;
}

#menuicon-bar.menuicon-left #sticky-menuicon {
	left: 50px;
}

/* ----------------------------------------------*/
/* 04.3 Sidebar Menu				 			 */
/*-----------------------------------------------*/

/* 04.3.1 Sidebar */
/* ----------------------------------------------*/
.sb-slidebar {
	background-color: #fff;
	width: 320px;
	border-left: 1px solid #f4f2f2;
	border-right: 1px solid #f4f2f2;
	z-index: 2000;
}

/* 04.3.2 Sidebar Header */
/* ----------------------------------------------*/
#side-navbar-header {
	position: relative;
	width: 100%;
	height: 140px;
	display: block;
}

#close-inner-wrapper {
	position: absolute;
	bottom: 30px;
	right: 35px;
}

#logo-inner-wrapper {
	position: absolute;
	bottom: 20px;
	left: 35px;
}

#close-button i {
	color: #666;
	font-size: 24px;
}

#close-button:hover i {
	color: #f0394d;
}

/* 04.3.3 Parent Menu */
/* ----------------------------------------------*/
#side-nav-menu {
	position: relative;
	list-style-type: none;
	margin: 35px;
	margin-bottom: 100px;
	padding: 0;
	font-family: "Oswald", sans-serif;
	font-size: 14px;
	z-index: 1000;
}

#side-nav-menu > li > a:before {
	font-family: "fontello";
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	content: "\e9bd";
	color: #f0394d;
	text-align: center;
	vertical-align: middle;
	padding-right: 10px;
}

#side-nav-menu > li a {
	display: block;
	padding: 14px 8px;
	color: #666;
	text-decoration: none;
	border-bottom: solid thin #f4f2f2;
}

#side-nav-menu > li a:hover {
	color: #f0394d;
}

/* 04.3.4 Child Menu */
/* ----------------------------------------------*/
#side-nav-menu ul {
	display: none;
	position: relative;
	left: 0;
	list-style-type: none;
	padding: 0;
	text-align: left;
}

#side-nav-menu ul.expand {
	display: block;
}

#side-nav-menu ul > li a {
	padding-left: 28px;
}

/* 04.3.5 Child-Parent Arrow Icon				 */
/* ----------------------------------------------*/
#side-nav-menu li a:after {
	content: ">";
	position: absolute;
	right: 15px;
}

#side-nav-menu li a:only-child:after {
	content: "";
}

/* 04.3.6 More Child Menu */
/* ----------------------------------------------*/
#side-nav-menu ul ul {
	display: none;
	position: relative;
	left: 0;
	list-style-type: none;
	padding: 0;
	text-align: left;
}

#side-nav-menu ul ul > li a {
	border-bottom: none;
}

/*===============================================*/
/* 05. LOGO	 	 	 							 */
/*===============================================*/

/* ----------------------------------------------*/
/* 05.1	CSS Logo								 */
/* ----------------------------------------------*/
.css-logo {
	width: 100%;
	height: 100%;
	border-radius: 0%;
	background-color: #f0394d;
	color: #fff;
	text-align: center;
	font-family: "Arvo", serif;
}

.css-logo.rounded {
	border-radius: 50%;
}

.css-logo-text {
	padding-top: 19px;
	font-size: 15px;
}

.css-logo-text > i {
	font-size: 10px;
}

/* ----------------------------------------------*/
/* 05.2	IMG Logo								 */
/* ----------------------------------------------*/
.img-logo,
.img-logo img {
	width: 100%;
}

/*===============================================*/
/* 06. MAIN-SLIDER					 		 	 */
/*===============================================*/
#main-slider {
	margin: 0;
	padding: 0;
	position: relative;
}

/* ----------------------------------------------*/
/* 06.1 Main Slider Height					     */
/* ----------------------------------------------*/
/* #main-slider.fullscreen - script.js			 */

#main-slider.fixed-height {
	position: relative;
	height: 625px;
}

/* ----------------------------------------------*/
/* 06.2 Main Slider Flexslider			  		 */
/* ----------------------------------------------*/
#main-slider.flexslider {
	border: none;
}

#main-slider .slides,
#main-slider.flexslider .slides li {
	position: relative;
	height: inherit;
	width: 100%;
	overflow: hidden;
}

#main-slider.flexslider .flex-viewport {
	position: relative;
	height: inherit;
}

/* 06.2.1 Main Slider Flexslider Control Nav & Paging */
/* ----------------------------------------------*/
#main-slider .flex-control-nav,
.flex-control-paging {
	background-color: "transparent !important";
	margin: 0;
	padding: 0;
	position: absolute;
	bottom: 0;
	z-index: 4;
}

#main-slider.flexslider .flex-direction-nav .flex-prev {
	left: 0px;
}
#main-slider.flexslider .flex-direction-nav .flex-next {
	right: 0px;
	text-align: right;
}
#main-slider.flexslider:hover .flex-prev {
	opacity: 0.7;
	left: 100px;
}
#main-slider.flexslider:hover .flex-next {
	opacity: 0.7;
	right: 100px;
}

#main-slider .flex-direction-nav a {
	z-index: 4;
	line-height: 40px;
}

/* ----------------------------------------------*/
/* 06.3 Main Slider Slide-Image		  		 	 */
/* ----------------------------------------------*/

#main-slider .slide-image {
	position: relative;
	height: inherit;
	width: 100%;
	background-size: cover;
	background-position: 50% 50%;
	background-color: #fff;
	-webkit-transform: translateZ(0);
}

/* ----------------------------------------------*/
/* 06.4 Main Slider Slide-Video			  		 */
/* ----------------------------------------------*/
#main-slider .slide-video {
	height: inherit;
	width: 100%;
	position: relative;
}

/*#main-slider .slide-video-control{
	height: 50px;
	width: 100%;
	position: absolute;
	bottom: 0;
	z-index: 4;
	padding-right:10px;
	text-align:right;
}*/

/* ----------------------------------------------*/
/* 06.5 Main Slider Title					     */
/* ----------------------------------------------*/

/* 06.5.1 Outter Wrapper */
/* ----------------------------------------------*/
.slide-title-outter-wrapper {
	position: absolute;
	width: 100%;
	height: inherit;
	left: 0;
	z-index: 3;
	overflow: hidden;
}

/* 06.5.2 Inner Wrapper */
/* ----------------------------------------------*/
.slide-title-inner-wrapper {
	width: 1170px;
	height: inherit;
	margin: 0 auto;
	display: table;
}

/* 06.5.3 Title Wrapper */
/* ----------------------------------------------*/
.slide-title {
	display: table-cell;
	text-align: center;
	color: #fff;
}

.slide-title h1,
.slide-title h2,
.slide-title h3,
.slide-title h4,
.slide-title h5,
.slide-title h6 {
	color: #fff;
}

/* 06.5.4 Title Alignment */
/* ----------------------------------------------*/
.slide-title.align-middle {
	vertical-align: middle;
}
.slide-title.align-top {
	vertical-align: top;
	/*padding-top:100px;*/
}
.slide-title.align-bottom {
	vertical-align: bottom;
	/*padding-bottom:0px;*/
}

/* 06.5.6 Save The Date Title */
/* ----------------------------------------------*/
#save-the-date {
	font-family: "Pacifico", cursive;
	-ms-transform: rotate(-10deg); /* IE 9 */
	-webkit-transform: rotate(-10deg); /* Chrome, Safari, Opera */
	transform: rotate(-10deg);
	padding-top: 100px;
	padding-right: 50px;
	margin-bottom: 20px;
}

#save {
	font-size: 75px;
	line-height: 50px;
}

#the-date {
	font-size: 62px;
}

#date {
	font-size: 47px;
	line-height: 40px;
}

.pink-dot {
	color: #f0394d;
}

/* ----------------------------------------------*/
/* 06.6 Each Slide Overlay					     */
/* ----------------------------------------------*/

.slide-overlay {
	position: absolute;
	top: 0;
	display: block;
	width: inherit;
	height: inherit;
	background-color: #000;
	opacity: 0.3;
	-webkit-transform: translateZ(0);
}

/* ----------------------------------------------*/
/* 06.7 Each Slide Caption					     */
/* ----------------------------------------------*/
.slide-caption {
	position: absolute;
	top: 0;
	display: block;
	width: inherit;
	height: inherit;
	color: #fff;
}

.use-animation {
	visibility: hidden;
}

/* ----------------------------------------------*/
/* 06.8 Hack Flexslider Fade for Firefox & IE11	 */
/* ----------------------------------------------*/
.flexslider .slides.css-fade > li {
	-webkit-transition: opacity 1s ease;
	-moz-transition: opacity 1s ease;
	transition: opacity 1s ease;
}

/* ----------------------------------------------*/
/* 06.9 Loop Animation						     */
/* ----------------------------------------------*/

/* 06.9.1 Zoom									 */
/* ----------------------------------------------*/
@-webkit-keyframes zoom-in-out {
	0% {
		-webkit-transform: scale(1); /* Chrome, Safari, Opera */
		transform: scale(1);
	}
	50% {
		-webkit-transform: scale(1.1); /* Chrome, Safari, Opera */
		transform: scale(1.1);
	}
	100% {
		-webkit-transform: scale(1); /* Chrome, Safari, Opera */
		transform: scale(1);
	}
}

@keyframes zoom-in-out {
	0% {
		-webkit-transform: scale(1); /* Chrome, Safari, Opera */
		transform: scale(1);
	}
	50% {
		-webkit-transform: scale(1.1); /* Chrome, Safari, Opera */
		transform: scale(1.1);
	}
	100% {
		-webkit-transform: scale(1); /* Chrome, Safari, Opera */
		transform: scale(1);
	}
}

.zoom.animate {
	-webkit-transform: scale(1); /* Chrome, Safari, Opera */
	transform: scale(1);
	-webkit-animation: zoom-in-out 30s; /* Chrome, Safari, Opera */
	animation: zoom-in-out 30s;
	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

/* 06.9.2 Left-Right							 */
/* ----------------------------------------------*/
@-webkit-keyframes move-left-right {
	0% {
		background-position: 50% 50%;
	}
	25% {
		background-position: 100% 50%;
	}
	50% {
		background-position: 50% 50%;
	}
	75% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 50% 50%;
	}
}

@keyframes move-left-right {
	0% {
		background-position: 50% 50%;
	}
	25% {
		background-position: 100% 50%;
	}
	50% {
		background-position: 50% 50%;
	}
	75% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 50% 50%;
	}
}

.left-right.animate {
	background-position: 50% 50%;
	-webkit-animation: move-left-right 240s; /* Chrome, Safari, Opera */
	animation: move-left-right 240s;
	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

/* 06.10 Video Audio Control					 */
/* ----------------------------------------------*/
#main-slider .video-audio-control {
	height: 50px;
	width: 100%;
	position: absolute;
	bottom: 0;
	z-index: 4;
	padding-right: 10px;
	text-align: right;
	color: #fff;
}

#main-slider .video-audio-control.mobile {
	display: none;
}

/*===============================================*/
/* 07. PAGE										 */
/*===============================================*/

/* ----------------------------------------------*/
/* 07.1	Page Layout								 */
/* ----------------------------------------------*/
#content {
	position: relative;
	background-color: #f6f6f6;
}

/* 07.1.1 Page Without Slider					 */
/* ----------------------------------------------*/
.no-slider-page #content {
	padding: 220px 0 120px 0;
}

/* 07.1.2 Page With Slider						 */
/* ----------------------------------------------*/
.slider-title-page #content {
	padding: 120px 0;
}

/* 07.1.3 Page With Banner						 */
/* ----------------------------------------------*/
.slider-banner-page #content {
	padding: 60px 0 120px 0;
}

/* 07.1.4 Page With Full Gallery				 */
/* ----------------------------------------------*/
.full-gallery-page #content {
	padding: 145px 0 120px 0;
}

/* 07.1.5 Page With Outside Bottom Bar 			 */
/* ----------------------------------------------*/
.outside-bottom-bar-page #content {
	margin-top: 145px;
}

/* 07.1.6 Page Sidebar				 			 */
/* ----------------------------------------------*/
.sidebar-wrapper {
	width: 100%;
	background-color: #fff;
	padding: 20px 30px;
}

.sidebar-menu {
	list-style-type: none;
	padding: 10px 0 0 0;
}

.sidebar-menu li {
	color: #6a6a6a;
	display: block;
	padding: 10px 0;
	border-bottom: 1px solid #ccc;
}

.sidebar-menu li a {
	color: #6a6a6a;
}

.sidebar-menu li a:hover {
	color: #f0394d;
}

.side-tab {
	margin-bottom: 60px;
}

/* ----------------------------------------------*/
/* 07.2 PAGE ELEMENT	 	 					 */
/* ----------------------------------------------*/

/* 07.2.1 Page Title Wrapper					 */
/* ----------------------------------------------*/
.page-title {
	padding-bottom: 50px;
}

/* 07.2.2 Section Title Wrapper					 */
/* ----------------------------------------------*/
.section-title {
	margin-bottom: 70px;
}

.section-title h2 {
	font-size: 40px; /*same size with h1*/
}

/* 07.2.3 Icon Title Wrapper					 */
/* ----------------------------------------------*/
.icon-title {
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	top: -50px;
}

/* 07.2.4 Title-Excerpt White Box				 */
/* ----------------------------------------------*/
.title-excerpt {
	position: relative;
	background-color: #fff;
	padding: 25px;
}

.title-excerpt .custom-heart-icon {
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	top: -12px;
	color: "#f0394d !important";
	background-color: "#e1dddd !important";
}

/* 07.2.5 Blurb Wrapper							 */
/* ----------------------------------------------*/
.blurb {
	margin-top: 20px;
}

/* 07.2.6  Align Middle Wrapper					 */
/* ----------------------------------------------*/
.alignment {
	position: absolute;
	display: table;
	height: 100%;
	width: 100%;
}

.v-align {
	display: table-cell;
}

.center-middle {
	vertical-align: middle;
	text-align: center;
}

.center-top {
	vertical-align: top;
	text-align: center;
}

.center-bottom {
	vertical-align: bottom;
	text-align: center;
}

/* 07.2.7 IMAGE & VIDEO DIVIDER					 */
/* ----------------------------------------------*/
.divider-wrapper {
	padding: 130px 0;
}

.image-divider {
	position: relative;
	background-size: cover;
	-webkit-background-size: cover;
	background-attachment: scroll;
	background-position: center center;
	background-repeat: no-repeat;
	color: #fff;
	-webkit-backface-visibility: hidden;
}

.image-divider.mobile {
	background-attachment: scroll;
}

.video-divider {
	position: relative;
	color: #fff;
	z-index: 3;
}

.image-divider h1,
.image-divider h2,
.image-divider h3,
.image-divider h4,
.image-divider h5,
.image-divider h6,
.video-divider h1,
.video-divider h2,
.video-divider h3,
.video-divider h4,
.video-divider h5,
.video-divider h6 {
	color: #fff;
}

.image-divider .divider-overlay,
.video-divider .divider-overlay {
	position: absolute;
	top: 0;
	display: block;
	width: 100%;
	height: 100%;
	background-color: #000;
	opacity: 0.3;
}

.image-divider.auto-height,
.video-divider.auto-height {
	height: 100%;
	width: 100%;
	padding: 150px 0;
}

.image-divider.fixed-height,
.video-divider.fixed-height {
	height: 700px;
	width: 100%;
	padding: 0;
}

/* 07.2.8 SPACE AND LINE DIVIDER 				 */
/* ----------------------------------------------*/
.space-divider {
	display: block;
	height: 150px;
}

hr.line-divider {
	height: 2px;
	border: none;
	color: #e1dddd;
	background-color: #e1dddd;
	margin: 50px 0;
}

/* 07.2.9 HEART DIVIDER							 */
/* ----------------------------------------------*/

.heart-divider {
	height: 100%;
	width: 100%;
	margin: 0 auto;
	margin-top: 20px;
	margin-bottom: 20px;
	text-align: center;
}

.white-line {
	width: 30px;
	height: 8px;
	display: inline-block;
	border-top: solid 1px #fff;
}

.grey-line {
	width: 30px;
	height: 8px;
	display: inline-block;
	border-top: solid 2px #e1dddd;
}

/*.left-line{
	color:#FFF; 
	letter-spacing:-2px; 
	float:left; 
	margin-right:10px
}

.right-line{
	color:#FFF; 
	letter-spacing:-2px; 
	float:left;
	margin-left:10px
}

.two-heart{
	float:left;
}*/

.pink-heart {
	color: #f0394d;
	font-size: 18px;
	position: relative;
	z-index: 3;
}

.white-heart {
	color: #fff;
	font-size: 18px;
	margin-left: -15px;
	position: relative;
	z-index: 2;
}

.grey-heart {
	color: #e1dddd;
	font-size: 18px;
	margin-left: -15px;
	position: relative;
	z-index: 2;
}

/* 07.2.10 BUTTON					 			 */
/* ----------------------------------------------*/
a.de-button,
span.de-button,
input[type="submit"] {
	display: inline-block;
	margin: 0 auto;
	margin-top: 20px;
	background-color: #fff;
	font-family: "Oswald", sans-serif;
	border: solid 1px #6a6a6a;
}

a.de-button.outline,
span.de-button.outline,
input[type="submit"].outline {
	background-color: transparent;
	border: solid 1px #fff;
	color: #fff;
}

a.de-button.reverse,
span.de-button.reverse,
input[type="submit"].reverse {
	background-color: #f0394d;
	border: solid 1px #f0394d;
	color: #fff;
}

a.de-button.small,
span.de-button.small,
input[type="submit"].small {
	padding: 8px 26px;
	font-size: 14px;
}

a.de-button.medium,
span.de-button.medium,
input[type="submit"].medium {
	padding: 10px 50px;
	font-size: 16px;
}

a.de-button.large,
span.de-button.large,
input[type="submit"].large {
	padding: 14px 70px;
	font-size: 20px;
}

a.de-button:hover,
input[type="submit"]:hover {
	background-color: #f0394d;
	border-color: #f0394d;
	color: #fff;
	text-decoration: none;
}

a.de-button.reverse:hover,
input[type="submit"].reverse:hover {
	background-color: #fff;
	border-color: #fff;
	color: #6a6a6a;
	text-decoration: none;
	border: solid 1px #6a6a6a;
}

input[type="submit"]:disabled,
input[type="submit"]:hover:disabled {
	background-color: "#CCC !important";
	border: solid 1px "#CCC !important";
	color: "#FFF !important";
}

/* 07.2.10 BUTTON	(Button Variant)				 			 */
/* ----------------------------------------------*/

a.de-button,
span.de-button,
button {
	display: inline-block;
	margin: 0 auto;
	margin-top: 20px;
	background-color: #fff;
	font-family: "Oswald", sans-serif;
	border: solid 1px #6a6a6a;
}

a.de-button.outline,
span.de-button.outline,
button.outline {
	background-color: transparent;
	border: solid 1px #fff;
	color: #fff;
}

a.de-button.reverse,
span.de-button.reverse,
button.reverse {
	background-color: #f0394d;
	border: solid 1px #f0394d;
	color: #fff;
}

a.de-button.small,
span.de-button.small,
button.small {
	padding: 8px 26px;
	font-size: 14px;
}

a.de-button.medium,
span.de-button.medium,
button.medium {
	padding: 10px 50px;
	font-size: 16px;
}

a.de-button.large,
span.de-button.large,
button.large {
	padding: 14px 70px;
	font-size: 20px;
}

a.de-button:hover,
button:hover {
	background-color: #f0394d;
	border-color: #f0394d;
	color: #fff;
	text-decoration: none;
}

a.de-button.reverse:hover,
button.reverse:hover {
	background-color: #fff;
	border-color: #fff;
	color: #6a6a6a;
	text-decoration: none;
	border: solid 1px #6a6a6a;
}

button:hover:disabled {
	background-color: "#CCC !important";
	border: solid 1px "#CCC !important";
	color: "#FFF !important";
}
/* 07.2.11 Banner Text				 			 */
/* ----------------------------------------------*/
.banner-text {
	display: inline-block;
	font-family: "Oswald", sans-serif;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 30px;
	text-align: center;
}

.banner-text.small {
	padding: 2px 30px;
}

.banner-text.medium {
	padding: 2px 50px;
}

.banner-text.large {
	padding: 2px 70px;
}

.banner-text.full {
	width: 100%;
}

.banner-text.light {
	color: #fff;
	border-top: medium double #fff;
	border-bottom: medium double #fff;
}

.banner-text.light * {
	color: #fff;
}

.banner-text.dark {
	color: #6a6a6a;
	border-top: medium double #6a6a6a;
	border-bottom: medium double #6a6a6a;
}

.banner-text.dark * {
	color: #333333;
}

.banner-text.withlove {
	position: relative;
	display: inline-block;
	font-family: "Oswald", sans-serif;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 30px;
	text-align: center;
}

.banner-text.withlove:before {
	display: block;
	font-family: "fontello";
	position: absolute;
	font-size: 22px;
	color: #fff;
	content: "\e806";
	top: -17px;
	left: 0;
	right: 0;
	margin-left: 19px;
}

.banner-text.withlove.dark:before {
	color: #e1dddd;
}

.banner-text.withlove:after {
	display: block;
	font-family: "fontello";
	position: absolute;
	font-size: 22px;
	color: #f0394d;
	content: "\e806";
	top: -17px;
	left: 0;
	right: 0;
	margin-right: 19px;
}

/* 07.2.12 White Box Content Wrapper 			 */
/* ----------------------------------------------*/
.white-box-content-wrapper {
	background-color: #fff;
	padding: 40px 40px;
	margin-bottom: 30px;
}

/*-----------------------------------------------*/
/* 07.2.13 Popup Template						 */
/*-----------------------------------------------*/
#popup-template {
	width: 900px;
	margin: 30px auto;
	background-color: #fff;
}

#popup-template #main-image {
	height: 485px;
	width: 100%;
	position: relative;
}

#popup-template-title-wrapper {
	position: absolute;
	bottom: 0;
	color: #fff;
	text-align: center;
	padding: 0 50px;
	margin: 0 auto;
	width: 100%;
}

#popup-template #the-content {
	padding: 35px 75px;
}

#popup-template #the-content #socialnetwork-icon {
	text-align: center;
	margin-bottom: 20px;
}

#popup-template #the-content img {
	width: 100%;
	margin: 10px 0;
}

/*-----------------------------------------------*/
/* 07.2.14 Autocrop Image						 */
/*-----------------------------------------------*/
.autocrop-image {
	display: block;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	overflow: hidden;
}

/*===============================================*/
/* 08. ICON										 */
/*===============================================*/
.de-icon {
	width: 60px;
	height: 60px;
	line-height: 60px;
	font-size: 30px;
	text-align: center;
	vertical-align: middle;
	margin: 8px 0px;

	-webkit-transition: 0.3s all ease;
	-moz-transition: 0.3s all ease;
	-o-transition: 0.3s all ease;
	-ms-transition: 0.3s all ease;
	transition: 0.3s all ease;
	/*The transition will be disabled on script.js for tablet/mobile*/
}

.de-icon.aligncenter {
	margin: 0 auto;
}

.de-icon.inline {
	display: inline-block;
	vertical-align: middle;
	margin-right: 8px;
}

.de-icon.float {
	float: left;
	margin-right: 15px;
}

.de-icon i {
	-webkit-transition: 0.3s all ease;
	-moz-transition: 0.3s all ease;
	-o-transition: 0.3s all ease;
	-ms-transition: 0.3s all ease;
	transition: 0.3s all ease;
	/*The transition will be disabled on script.js for tablet/mobile*/
}

/*------------------------------------------------*/
/* 08.1	 Size 							   	  	  */
/*------------------------------------------------*/
.de-icon.large-size {
	width: 100px;
	height: 100px;
	line-height: 100px;
	font-size: 40px;
}

.de-icon.medium-size {
	width: 54px;
	height: 54px;
	line-height: 54px;
	font-size: 21px;
}

.de-icon.small-size {
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 18px;
}

.de-icon.very-small-size {
	width: 25px;
	height: 25px;
	line-height: 25px;
	font-size: 11px;
}

/*------------------------------------------------*/
/* 08.2 Shape									  */
/*------------------------------------------------*/

/* 08.2.1 Default								  */
/*------------------------------------------------*/
.de-icon.default {
	color: #6a6a6a;
}

.de-icon.default.light {
	color: #fff;
}

.de-icon.default.reverse {
	color: #f0394d;
}

a:hover .de-icon.default,
a:hover .de-icon.default.light {
	color: #f0394d;
}

a:hover .de-icon.default.reverse {
	color: #6a6a6a;
}

a:hover .de-icon.default.light.reverse {
	color: #fff;
}

/* 08.2.2 Circle, Rounded, Square				  */
/*------------------------------------------------*/
.de-icon.circle {
	border-radius: 50%;
}

.de-icon.rounded {
	border-radius: 20%;
}

.de-icon.square,
.de-icon.circle,
.de-icon.rounded {
	background-color: #6a6a6a;
	color: #fff;
}

.de-icon.square.reverse,
.de-icon.circle.reverse,
.de-icon.rounded.reverse {
	background-color: #f0394d;
	color: #fff;
}

.de-icon.square.light,
.de-icon.circle.light,
.de-icon.rounded.light {
	background-color: #fff;
	color: #6a6a6a;
}

.de-icon.square.light.reverse,
.de-icon.circle.light.reverse,
.de-icon.rounded.light.reverse {
	background-color: #fff;
	color: #f0394d;
}

.de-icon.outline {
	background-color: transparent;
	color: #6a6a6a;
	border: 1px solid #6a6a6a;
}

.de-icon.outline.reverse {
	background-color: transparent;
	color: #f0394d;
	border: 1px solid #f0394d;
}

.de-icon.outline.light,
.de-icon.outline.light.reverse {
	background-color: transparent;
	color: #fff;
	border: 1px solid #fff;
}

a:hover .de-icon.circle,
a:hover .de-icon.rounded,
a:hover .de-icon.square {
	background-color: #f0394d;
	color: #fff;
	border: none;
}

a:hover .de-icon.circle.reverse,
a:hover .de-icon.rounded.reverse,
a:hover .de-icon.square.reverse {
	background-color: #6a6a6a;
	color: #fff;
	border: none;
}

/*------------------------------------------------*/
/* 08.3 Effect Zoom 						 	  */
/*------------------------------------------------*/
.de-icon.effect1 {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

a:hover .de-icon.effect1 {
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-o-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);
}

/*------------------------------------------------*/
/* 08.4 Effect Rotate						  	  */
/*------------------------------------------------*/
.de-icon.effect2 i {
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	transform: rotate(0deg);
}

a:hover .de-icon.effect2 i {
	-webkit-transform: rotate(360deg);
	-moz-transform: rotate(360deg);
	-o-transform: rotate(360deg);
	-ms-transform: rotate(360deg);
	transform: rotate(360deg);
}

/*------------------------------------------------*/
/* 08.5 Effect Square/Rounded To Circle 	  	  */
/*------------------------------------------------*/
a:hover .de-icon.effect3 {
	background-color: #f0394d;
	color: #fff;
	border-radius: 50%;
}

/*===============================================*/
/* 09. PHOTO ITEM								 */
/*===============================================*/
.photo-item {
	position: relative;
	overflow: hidden;
}

.photo-item.frame-border {
	background-color: #fff;
	border: 18px solid #fff;
}

/* ----------------------------------------------*/
/* 09.1 Image 									 */
/* ----------------------------------------------*/
.photo-item img {
	width: 100%;
}

/* ----------------------------------------------*/
/* 09.2 Layer 								 	 */
/* ----------------------------------------------*/
.photo-item .layer {
	position: absolute;
	margin: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.photo-item .layer.wh100 {
	width: 100%;
	height: 100%;
}

.photo-item .layer.wh95 {
	width: 95%;
	height: 95%;
}

/* 09.2.1 Layer : Second Image   				 */
/* ----------------------------------------------*/
.photo-item .layer.second-image {
	opacity: 0;
}

/* 09.2.2 Layer : Black Overlay  				 */
/* ----------------------------------------------*/
.photo-item .layer.opacity-black-overlay {
	background-color: rgba(0, 0, 0, 0.4);
	opacity: 0.3;
}

.photo-item .layer.hidden-black-overlay {
	background-color: rgba(0, 0, 0, 0.4);
	opacity: 0;
}

/* 09.2.3 Layer : Photo Caption  				 */
/* ----------------------------------------------*/
.photo-item .layer.photo-caption {
	opacity: 1;
}

.photo-item .layer.hidden-photo-caption {
	opacity: 0;
}

.photo-item .layer.border-photo-caption {
	opacity: 1;
	border: 1px solid #fff;
}

.photo-item .layer.hidden-border-photo-caption {
	opacity: 0;
	border: 1px solid #fff;
}

/* 09.2.4 Layer : Icon / Button Link		 	 */
/* ----------------------------------------------*/
.photo-item .layer.link {
	opacity: 1;
}

.photo-item .layer.hidden-link {
	opacity: 0;
}

.photo-item:hover .layer.hover-animation.fade-in {
	opacity: 1;
}

.photo-item:hover .layer.hover-animation.half-fade-in {
	opacity: 0.3;
}

.photo-item:hover .layer.hover-animation.fade-out {
	opacity: 0;
}

/* ----------------------------------------------*/
/* 09.3 on Hover Animation					 	*/
/* ----------------------------------------------*/
.photo-item img.hover-animation,
.photo-item:hover img.hover-animation {
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	/*The transition will be disabled on script.js for tablet/mobile*/
}

.photo-item .layer.hover-animation,
.photo-item:hover .layer.hover-animation {
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	/*The transition will be disabled on script.js for tablet/mobile*/
}

/* 09.3.1 on Hover Animation Duration		 	 */
/* ----------------------------------------------*/
.photo-item:hover .layer.hover-animation.duration1,
.photo-item:hover img.hover-animation.duration1 {
	transition-duration: 0.5s;
}

.photo-item:hover .layer.hover-animation.duration2,
.photo-item:hover img.hover-animation.duration12 {
	transition-duration: 1s;
}

.photo-item:hover .layer.hover-animation.duration3,
.photo-item:hover img.hover-animation.duration3 {
	transition-duration: 1.5s;
}

.photo-item:hover .layer.hover-animation.duration4,
.photo-item:hover img.hover-animation.duration4 {
	transition-duration: 2s;
}

.photo-item:hover .layer.hover-animation.duration5,
.photo-item:hover img.hover-animation.duration5 {
	transition-duration: 2.5s;
}

/* 09.3.2 on Hover Animation Delay		 	 	 */
/* ----------------------------------------------*/
.photo-item:hover .layer.hover-animation.delay1,
.photo-item:hover img.hover-animation.delay1 {
	transition-delay: 0.5s;
}

.photo-item:hover .layer.hover-animation.delay2,
.photo-item:hover img.hover-animation.delay2 {
	transition-delay: 1s;
}

.photo-item:hover .layer.hover-animation.delay3,
.photo-item:hover img.hover-animation.delay3 {
	transition-delay: 1.5s;
}

.photo-item:hover .layer.hover-animation.delay4,
.photo-item:hover img.hover-animation.delay4 {
	transition-delay: 2s;
}

.photo-item:hover .layer.hover-animation.delay5,
.photo-item:hover img.hover-animation.delay5 {
	transition-delay: 2.5s;
}

/* 09.3.3 on Hover Zoom In					 	 */
/* ----------------------------------------------*/
.photo-item:hover img.hover-animation.image-zoom-in {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	-o-transform: scale(1.1);
	transform: scale(1.1);
}

/* 09.3.4 on Hover Zoom In and Rotate	 	 	*/
/* ----------------------------------------------*/
.photo-item:hover img.hover-animation.image-zoom-rotate {
	-webkit-transform: rotate(7deg) scale(1.1);
	-ms-transform: rotate(7deg) scale(1.1);
	-o-transform: rotate(7deg) scale(1.1);
	transform: rotate(7deg) scale(1.1);
}

/* 09.3.5 on Hover Fade Out				 		 */
/* ----------------------------------------------*/
.photo-item:hover img.hover-animation.image-fade-out {
	opacity: 0;
}

/*===============================================*/
/* 10. MASONRY GALLERY							 */
/*===============================================*/
.masonry-col.w100 {
	width: 100%;
}

/* ----------------------------------------------*/
/* 10.1 Three Column No Gutter		 			 */
/* ----------------------------------------------*/
.three-col .grid-sizer {
	width: 33.33%;
}

.three-col .gutter-sizer {
	width: 0;
}

.three-col .masonry-col.w33 {
	width: 33.33%;
}

.three-col .masonry-col.w66 {
	width: 66.67%;
}

/* ----------------------------------------------*/
/* 10.2 Three Column With Gutter	 			 */
/* ----------------------------------------------*/
.three-col.with-gutter .grid-sizer {
	width: 31%;
}

.three-col.with-gutter .gutter-sizer {
	width: 3.5%;
}

.three-col.with-gutter .masonry-col {
	margin-bottom: 30px;
}

.three-col.with-gutter .masonry-col.w33 {
	width: 31%;
}

.three-col.with-gutter .masonry-col.w66 {
	width: 62%;
}

/* ----------------------------------------------*/
/* 10.3 Four Column No Gutter		 			 */
/* ----------------------------------------------*/
.four-col .grid-sizer {
	width: 25%;
}

.four-col .gutter-sizer {
	width: 0;
}

.four-col .masonry-col.w25 {
	width: 25%;
}

.four-col .masonry-col.w50 {
	width: 50%;
}

.four-col .masonry-col.w75 {
	width: 75%;
}

/* ----------------------------------------------*/
/* 10.4 Four Column With Gutter		 			 */
/* ----------------------------------------------*/
.four-col.with-gutter .grid-sizer {
	width: 23%;
}

.four-col.with-gutter .gutter-sizer {
	width: 2.666%;
}

.four-col.with-gutter .masonry-col {
	margin-bottom: 25px;
}

.four-col.with-gutter .masonry-col.w25 {
	width: 23%;
}

.four-col.with-gutter .masonry-col.w50 {
	width: 46%;
}

.four-col.with-gutter .masonry-col.w75 {
	width: 69%;
}

/* ----------------------------------------------*/
/* 10.5 Five Column No Gutter		 			 */
/* ----------------------------------------------*/
.five-col .grid-sizer {
	width: 20%;
}

.five-col .gutter-sizer {
	width: 0;
}

.five-col .masonry-col.w20 {
	width: 20%;
}

.five-col .masonry-col.w40 {
	width: 40%;
}

.five-col .masonry-col.w60 {
	width: 60%;
}

.five-col .masonry-col.w80 {
	width: 80%;
}

/* ----------------------------------------------*/
/* 10.6 Five Column With Gutter		 			 */
/* ----------------------------------------------*/
.five-col.with-gutter .grid-sizer {
	width: 18%;
}

.five-col.with-gutter .gutter-sizer {
	width: 2.5%;
}

.five-col.with-gutter .masonry-col {
	margin-bottom: 25px;
}

.five-col.with-gutter .masonry-col.w20 {
	width: 18%;
}

.five-col.with-gutter .masonry-col.w40 {
	width: 36%;
}

.five-col.with-gutter .masonry-col.w60 {
	width: 54%;
}

.five-col.with-gutter .masonry-col.w80 {
	width: 72%;
}

/*===============================================*/
/* 11. FORM										 */
/*===============================================*/

/*-----------------------------------------------*/
/* 11.1 Form Group								 */
/*-----------------------------------------------*/
.form-group {
	margin-bottom: 30px;
}

.form-group.submit-wrapper {
	margin-top: 30px;
}

/*-----------------------------------------------*/
/* 11.2 Form Control							 */
/*-----------------------------------------------*/
.form-control {
	height: 35px;
	border-radius: 0;
	font-size: 13px;
}

.form-control:focus {
	border-color: #cccccc;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #cccccc;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #cccccc;
}

/*-----------------------------------------------*/
/* 11.3 Input Group								 */
/*-----------------------------------------------*/
.input-group-addon {
	border-radius: 0;
}

/*-----------------------------------------------*/
/* 11.4 Label									 */
/*-----------------------------------------------*/
form label {
	font-family: "Oswald", sans-serif;
	font-size: 14px;
	font-weight: normal;
}

.option-label {
	font-family: "Arvo", serif;
	color: #6a6a6a;
	font-size: 13px;
}

/*-----------------------------------------------*/
/* 11.5 Button									 */
/*-----------------------------------------------*/
form .btn-primary {
	padding: 12px 18px;
	margin: 0 15px 5px 15px;
	border-radius: 0;
	font-family: "Arvo", serif;
	color: #6a6a6a;
	font-size: 13px;
	background-color: #fff;
	border-color: #cccccc;
}

form .btn-primary:hover,
form .btn-primary:focus,
form .btn-primary.focus,
form .btn-primary:active,
form .btn-primary.active,
.open > .dropdown-toggle.btn-primary {
	background-color: #f0394d;
	border-color: #f0394d;
	color: #fff;
	border-color: #cccccc;
}

/*-----------------------------------------------*/
/* 11.6 Checkbox and Radio (Custom)				 */
/*-----------------------------------------------*/
.ajax-checkbox .btn input[type="checkbox"],
.ajax-radio .btn input[type="radio"] {
	display: none;
}

.ajax-checkbox .btn.custom-option-icon,
.ajax-checkbox .btn.no-icon,
.ajax-radio .btn.custom-option-icon,
.ajax-radio .btn.no-icon {
	margin-left: 0;
	text-align: left;
}

.ajax-checkbox .btn.custom-option-icon:before {
	font-family: "fontello";
	content: "\ecd4";
	margin-right: 7px;
}

.ajax-checkbox .btn.active-icon.custom-option-icon:before {
	font-family: "fontello";
	content: "\ecd3";
	margin-right: 7px;
}

.ajax-radio .btn.custom-option-icon:before {
	font-family: "fontello";
	content: "\e914";
	margin-right: 7px;
	font-size: 16px;
	font-weight: bold;
}

.ajax-radio .btn.active-icon.custom-option-icon:before {
	font-family: "fontello";
	content: "\e917";
	margin-right: 7px;
	font-size: 16px;
	font-weight: bold;
}

/*-----------------------------------------------*/
/* 11.7 Select Option							 */
/*-----------------------------------------------*/

select option {
	padding: "4px 0 !important";
}

option {
	color: #747474;
}

/*-----------------------------------------------*/
/* 11.8 Message									 */
/*-----------------------------------------------*/
.message {
	margin-bottom: 20px;
	font-size: 13px;
}

.message .bg-danger,
.message .bg-success {
	padding: 8px 14px;
	color: #ffffff;
}

.message .bg-danger {
	background-color: #f0394d;
}

.message .bg-success {
	background-color: #6a6a6a;
}

/*-----------------------------------------------*/
/* 11.9 Validation								 */
/*-----------------------------------------------*/
form .has-error .btn-primary {
	border-color: #f397a1;
}

.has-error .form-control {
	border-color: #f397a1;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-error .form-control:focus {
	border-color: #f397a1;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #f397a1;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #f397a1;
}

/*===============================================*/
/* 12. HOME PAGE								 */
/*===============================================*/

/* ----------------------------------------------*/
/* 12.1 Couple Section							 */
/* ----------------------------------------------*/

#couple {
	padding: 140px 0 100px 0;
}

.couple-name {
	font-family: "Pacifico", cursive;
	font-size: 41px;
	padding-top: 100px;
	color: #fff;
	line-height: 41px;
	-ms-transform: rotate(-10deg);
	-webkit-transform: rotate(-10deg);
	transform: rotate(-10deg);
}

.couple-excerpt {
	margin-top: 40px;
	margin-bottom: 40px;
}

/* ----------------------------------------------*/
/* 12.2 Location & Countdown Section			 */
/* ----------------------------------------------*/

#counting-down {
	text-align: center;
	color: #fff;
	font-family: "Pacifico";
	-ms-transform: rotate(-10deg);
	-webkit-transform: rotate(-10deg);
	transform: rotate(-10deg);
	padding-right: 30px;
	margin-top: 0px;
	margin-bottom: 25px;
}

#counting {
	font-size: 65px;
	line-height: 50px;
}

#down {
	font-size: 55px;
	line-height: 65px;
}

.simple-countdown {
	width: 250px;
	margin: 0 auto;
	margin-top: 25px;
}

.simple-countdown.is-countdown {
	border: none;
	background-color: transparent;
	color: #fff;
	font-family: "Oswald", sans-serif;
	font-size: 13px;
}

.simple-countdown .countdown-amount {
	display: inline-block;
	font-size: 18px;
	letter-spacing: 2px;
}
.simple-countdown .countdown-period {
	display: inline-block;
	font-size: 18px;
}

/* ----------------------------------------------*/
/* 12.3 The Wedding Section			 			 */
/* ----------------------------------------------*/
#the-wedding {
	padding: 140px 0 140px 0;
}

#the-wedding h4 {
	font-size: 17px;
}

.wedding-item {
	margin-bottom: 40px;
}

/* ----------------------------------------------*/
/* 12.4 RSVP Section				 			 */
/* ----------------------------------------------*/
#rsvp {
	padding: 85px 0;
	background-color: #fff;
}

/* ----------------------------------------------*/
/* 12.5 Footer Section				 			 */
/* ----------------------------------------------*/
footer .image-divider.fixed-height {
	height: 550px;
}

#thank-you {
	text-align: center;
	color: #fff;
	font-family: "Pacifico";
	-ms-transform: rotate(-10deg);
	-webkit-transform: rotate(-10deg);
	transform: rotate(-10deg);
	padding-right: 30px;
	margin-top: 40px;
	margin-bottom: 25px;
}

#thank {
	font-size: 55px;
	line-height: 45px;
}

#you {
	font-size: 55px;
	line-height: 45px;
}

#footer-couple-name {
	font-family: "Oswald", sans-serif;
	font-size: 20px;
	color: #fff;
	letter-spacing: 3px;
	margin-top: 10px;
}

/*===============================================*/
/* 13. OTHER PAGE								 */
/*===============================================*/

/* ----------------------------------------------*/
/* 13.1 Location Page				 			 */
/* ----------------------------------------------*/
.interest-icon {
	display: inline-block;
	width: 20%;
	vertical-align: top;
	text-align: center;
}

.interest-blurb {
	display: inline-block;
	width: 75%;
	vertical-align: top;
}

/* ----------------------------------------------*/
/* 13.2 RSVP-2 Page				 			 	 */
/* ----------------------------------------------*/
.rsvp-wrapper {
	padding: 35px 20px 20px 25px;
	border: 1px solid #cccccc;
	border-radius: 10px;
}

/* ----------------------------------------------*/
/* 13.3 Bridesmaid Page				 			 */
/* ----------------------------------------------*/
.bridesmaid-wrapper {
	margin-bottom: 40px;
}

/* ----------------------------------------------*/
/* 13.4 Groomsmen Page				 			 */
/* ----------------------------------------------*/
.groomsmen-wrapper {
	margin-bottom: 40px;
}

/* ----------------------------------------------*/
/* 13.5 Blog Page				 			 	 */
/* ----------------------------------------------*/
.blog-wrapper {
	margin-bottom: 40px;
}

/*===============================================*/
/* 14. ONEPAGE SECTION							 */
/*===============================================*/

/* ----------------------------------------------*/
/* 14.1 Slider Section							 */
/* ----------------------------------------------*/
#invited {
	font-family: "Pacifico", cursive;
	font-size: 36px;
	margin-top: 70px;
}

#banner-date {
	font-family: "Oswald", sans-serif;
	letter-spacing: 3px;
	font-size: 14px;
}

/* ----------------------------------------------*/
/* 14.2 Couple Section							 */
/* ----------------------------------------------*/
.his-her-name {
	position: relative;
}

.his-her-name h2 {
	font-size: 40px;
	margin-top: 50px;
	margin-bottom: 20px;
}

.his-her-name .last-name {
	font-family: "Pacifico", cursive;
	font-size: 24px;
	display: block;
	margin-top: 0;
}

.his-her-name .heart-wrapper {
	position: absolute;
	right: -50px;
	top: 0;
}

.his-her-name .custom-heart {
	background-color: #f0394d;
	width: 65px;
	height: 65px;
	line-height: 65px;
	font-size: 26px;
}

/* ----------------------------------------------*/
/* 14.3 Our Story Section						 */
/* ----------------------------------------------*/
#our-story {
	padding: 140px 0;
}

.story-row.row {
	display: table;
	padding: 50px 0;
	position: relative;
}

.story-row.row [class*="col-"] {
	float: none;
	display: table-cell;
	vertical-align: middle;
}

.story-date-wrapper {
	z-index: 1;
}

.story-date {
	float: left;
	margin-left: 16px;
	height: 85px;
	width: 85px;
	background-color: #f0394d;
	border-radius: 50%;
}

.story-date .date-only {
	font-family: "Oswald", sans-serif;
	font-size: 30px;
	color: #fff;
	line-height: 30px;
	margin-top: 16px;
}

.story-date .month-year {
	font-family: "Oswald", sans-serif;
	font-size: 16px;
	color: #fff;
}

.arrow-right {
	width: 0;
	height: 0;
	border-top: 13px solid transparent;
	border-bottom: 13px solid transparent;

	border-left: 13px solid #e1dddd;
	float: right;
	margin-bottom: 22px;
	margin-left: 10px;
	margin-top: 30px;
}

.arrow-left {
	width: 0;
	height: 0;
	border-top: 13px solid transparent;
	border-bottom: 13px solid transparent;

	border-right: 13px solid #e1dddd;
	float: left;
	margin-bottom: 22px;
	margin-right: 10px;
	margin-top: 30px;
}

.vertical-line {
	position: absolute;
	height: 100%;
	width: 1px;
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border-left: 1px solid #e1dddd;
}

#end-of-story {
	margin-top: 130px;
}

/* ----------------------------------------------*/
/* 14.4 Where When Section						 */
/* ----------------------------------------------*/
#where-when {
	padding: 120px 0;
}

.and {
	color: #f0394d;
}

.border-column {
	border-left: 1px solid #8b8b8b;
	border-right: 1px solid #8b8b8b;
}

#map-canvas {
	height: 560px;
	width: 100%;
}

/* ----------------------------------------------*/
/* 14.5 Gallery Section							 */
/* ----------------------------------------------*/
#gallery-section {
	padding: 140px 0;
}

#gallery-section .photo-item {
	margin-bottom: 30px;
}

/* ----------------------------------------------*/
/* 14.6 Don't Miss It / Countdown Section		 */
/* ----------------------------------------------*/
h2#dont-miss-it-title {
	font-size: 42px;
	margin-bottom: 30px;
	font-family: "Pacifico", cursive;
}

.circle-countdown {
	width: 100%;
	margin: 0 auto;
	margin-top: 25px;
}

.circle-countdown.is-countdown {
	background-color: transparent;
	border: none;
	font-family: "Oswald", sans-serif;
}

.circle-countdown .countdown-amount {
	background-color: #f0394d;
	width: 85px;
	height: 85px;
	border-radius: 50%;
	display: block;
	padding-top: 22px;
	font-size: 30px;
	margin: 0 auto;
}

.circle-countdown .countdown-period {
	padding: 10px;
	display: block;
	text-align: center;
	font-size: 16px;
	text-transform: uppercase;
}

/* ----------------------------------------------*/
/* 14.7 More Events Section						 */
/* ----------------------------------------------*/
#more-events {
	padding: 140px 0;
}

#events-carousel .item {
	padding: 20px;
	margin-bottom: 10px;
}

/* ----------------------------------------------*/
/* 14.8 Groomsmen Section						 */
/* ----------------------------------------------*/
#groomsmen-section {
	padding: 140px 0;
}

.groomsmen-item {
	margin-bottom: 60px;
}

/* ----------------------------------------------*/
/* 14.9 Bridesmaid Section						 */
/* ----------------------------------------------*/
#bridesmaid-section {
	padding: 140px 0;
}

.bridesmaid-item {
	margin-bottom: 60px;
}

/* ----------------------------------------------*/
/* 14.10 Gift Section							 */
/* ----------------------------------------------*/
#gift-section {
	padding: 140px 0;
	background-color: #fff;
}

/* ----------------------------------------------*/
/* 14.11 RSVP Section							 */
/* ----------------------------------------------*/
#rsvp-section {
	padding: 140px 0;
}

/*===============================================*/
/* 15. PLUGIN CUSTOM							 */
/*===============================================*/

/* -------------------------------------------*/
/* 15.1 Owl Carousel 						  */
/* -------------------------------------------*/
.owl-carousel .item {
	padding: 0 15px;
}

.owl-theme .owl-controls .owl-page span {
	display: block;
	width: 8px;
	height: 8px;
	margin: 5px 7px;
	opacity: 1;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	background: #333333;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
	background: #f0394d;
}

/* -------------------------------------------*/
/* 15.2 MAGNIFIC POPUP 						  */
/* -------------------------------------------*/
.mfp-bg {
	z-index: 2000;
	-webkit-transform: translateZ(0);
}

.mfp-wrap {
	z-index: 2001;
}

.mfp-content {
	z-index: 2003;
}

.mfp-preloader {
	z-index: 2002;
}

button.mfp-close,
button.mfp-arrow {
	z-index: 2003;
}

/*===============================================*/
/* 16. MEDIA SCREEN		 						 */
/*===============================================*/
@media screen and (max-width: 1200px) {
	/* ----------------------------------------------*/
	/* 02.1 Width & Color (Nav Bar)			 		 */
	/* ----------------------------------------------*/

	/* 02.1.1 Fixed-width 							 */
	/* ----------------------------------------------*/
	#nav-bar.fixed-width #nav-wrapper {
		width: 100%;
	}

	/* ----------------------------------------------*/
	/* 04.1 Menuicon Bar Width & Position	 		 */
	/* ----------------------------------------------*/

	/* 04.1.1 Fixed-width 							 */
	/* ----------------------------------------------*/
	#menuicon-bar.fixed-width {
		width: 100%;
	}

	/* 06.5.2 Inner Wrapper 						 */
	/* ----------------------------------------------*/
	.slide-title-inner-wrapper {
		width: 100%;
	}
}

@media screen and (max-width: 991px) {
	/*===============================================*/
	/* 02. NAV BAR 	 							 	 */
	/*===============================================*/

	#nav-bar {
		height: 100px;
	}

	#nav-bar-sticky-wrapper {
		height: "100px !important";
	}

	#nav-bar.top-bar.stick-it {
		height: 100px;
	}

	#nav-bar.bottom-bar.stick-it {
		height: 100px;
	}

	#nav-bar.bottom-bar.outside {
		bottom: -100px;
	}

	/* ----------------------------------------------*/
	/* 02.1 Width & Color (Nav Bar)			 		 */
	/* ----------------------------------------------*/
	/* 02.1.1 Fixed-width */
	/* 02.1.2 Fluid-width */
	#nav-bar.fluid-width #nav-wrapper,
	#nav-bar.fixed-width #nav-wrapper {
		padding: 20px 30px;
	}

	/* ----------------------------------------------*/
	/* 02.2 Logo & Nav Position	 					 */
	/* ----------------------------------------------*/
	.logo-wrapper {
		margin: 0;
	}

	#nav-menu {
		display: none;
	}
	#nav-menu.sidebar {
		display: block;
	}
	#nav-menu.open {
		display: block;
	}

	#nav-menu,
	.bottom-bar.transparent #nav-menu {
		list-style-type: none;
		margin: 0;
		padding: "0 !important";
		position: absolute;
		left: 0;
		top: 100%;
		clear: "both !important";
		text-align: "left !important";
		width: "100% !important";
		background-color: "#FFF";
		height: 330px;
		overflow: auto;
	}

	.transparent #nav-menu {
		width: "80% !important";
		position: absolute;
		margin: auto;
		top: 100%;
		left: 0;
		right: 0;
	}

	.transparent.stick-it #nav-menu {
		width: "100% !important";
	}

	/* 02.2.1 Nav Center */
	/* 02.2.2 Nav Left 	 */
	/* 02.2.3 Nav Right  */
	#nav-bar.nav-center .logo-wrapper,
	#nav-bar.nav-left .logo-wrapper,
	#nav-bar.nav-right .logo-wrapper {
		position: relative;
		float: left;
	}

	/*===============================================*/
	/* 03. NAV MENU	 	 							 */
	/*===============================================*/

	/* ----------------------------------------------*/
	/* 03.1 Parent Menu 						     */
	/* ----------------------------------------------*/
	#nav-menu,
	#nav-menu ul {
		-webkit-overflow-scrolling: touch;
	}

	#nav-menu li {
		position: relative;
	}

	#nav-menu li a {
		display: block;
		width: 100%;
		color: #666;
		border-bottom: solid thin #f4f2f2;
	}

	.transparent #nav-menu li a {
		color: #666;
	}

	#nav-menu li a:hover {
		background-color: #f0394d;
		color: #fff;
	}

	#nav-menu li:before {
		content: "";
	}

	/* 03.1.2 Additional Class For Nav Center */
	/* ----------------------------------------------*/
	.first-child-margin,
	.last-child-margin,
	.split-margin {
		margin: 0;
	}

	/* ----------------------------------------------*/
	/* 03.2 Child Menu 								 */
	/* ----------------------------------------------*/
	#nav-menu ul {
		display: block;
		width: 100%;
		position: relative;
		top: 0;
		text-align: left;
		border-top: none;
	}

	#nav-menu li > ul li a:before {
		content: "-";
		padding-right: 10px;
	}

	/* 03.2.2 Child-Parent Arrow */
	/* ----------------------------------------------*/
	#nav-menu li > ul li a:after {
		content: "";
	}

	/* 03.2.3 More Child */
	/* ----------------------------------------------*/
	#nav-menu ul ul {
		display: block;
		width: 100%;
		position: relative;
		top: 0;
		left: 0;
		border: none;
	}

	#nav-menu ul ul li a:before {
		content: "- -";
		padding-right: 10px;
	}

	#nav-menu ul ul ul li a:before {
		content: "- - -";
		padding-right: 10px;
	}

	/* ----------------------------------------------*/
	/* 03.3 Mobile Nav								 */
	/* ----------------------------------------------*/
	#mobile-nav {
		display: block;
		position: relative;
		float: right;
		font-size: 28px;
		margin-top: 12px;
		z-index: 1000;
	}

	.transparent #mobile-nav {
		color: #fff;
	}

	.transparent.stick-it #mobile-nav {
		color: #666;
	}

	/* ----------------------------------------------*/
	/* 04.1 Menuicon Bar Width & Position 			 */
	/*-----------------------------------------------*/
	#menuicon-bar {
		top: 0;
		padding: 0 30px;
	}

	/* 04.1.3 Menuicon-Left */
	/* ----------------------------------------------*/
	#menuicon-bar.menuicon-left .logo-outter-wrapper {
		margin-top: 40px;
	}

	/* 04.1.4 Menuicon-Right */
	/* ----------------------------------------------*/
	#menuicon-bar.menuicon-right .logo-outter-wrapper {
		margin-top: 40px;
	}

	/* 04.2.2 Sticky Menu Icon */
	/* ----------------------------------------------*/
	#sticky-menuicon {
		top: 10px;
	}

	#menuicon-bar.menuicon-right #sticky-menuicon {
		right: 30px;
	}

	#menuicon-bar.menuicon-left #sticky-menuicon {
		left: 30px;
	}

	/* 06.2.1 Main Slider Flexslider Control Nav & Paging */
	/* ----------------------------------------------*/
	#main-slider.flexslider:hover .flex-prev {
		opacity: 0.4;
		left: 20px;
	}
	#main-slider.flexslider:hover .flex-next {
		opacity: 0.4;
		right: 20px;
	}
	#main-slider.flexslider .flex-direction-nav .flex-prev {
		opacity: 0.4;
		left: 20px;
	}
	#main-slider.flexslider .flex-direction-nav .flex-next {
		opacity: 0.4;
		right: 20px;
	}

	/* ----------------------------------------------*/
	/* 07.1 PAGE LAYOUT						 		 */
	/* ----------------------------------------------*/

	/* 07.1.1 Page Without Slider					 */
	/* ----------------------------------------------*/
	.no-slider-page #content {
		padding: 140px 0 80px 0;
	}

	/* 07.1.2 Page With Slider					 	 */
	/* ----------------------------------------------*/
	.slider-title-page #content {
		padding: 80px 0;
	}

	/* 07.1.3 Page With Banner						 */
	/* ----------------------------------------------*/
	.slider-banner-page #content {
		padding: 60px 0 80px 0;
	}

	/* 07.1.4 Page With Full Gallery				 */
	/* ----------------------------------------------*/
	.full-gallery-page #content {
		padding: 100px 0 80px 0;
	}

	/* 07.1.5 Page With Outside Bottom Bar 			 */
	/* ----------------------------------------------*/
	.outside-bottom-bar-page #content {
		margin-top: 100px;
	}

	/* 07.2.7 Image & Video Divider					 */
	/* ----------------------------------------------*/
	.divider-wrapper {
		padding: 70px 0;
	}

	.image-divider {
		background-attachment: scroll;
	}

	/*-----------------------------------------------*/
	/* 07.2.13 Popup Template						 */
	/*-----------------------------------------------*/
	#popup-template {
		width: 90%;
	}

	/*===============================================*/
	/* 13 LOCATION PAGE								 */
	/*===============================================*/
	.interest-icon {
		width: 10%;
	}

	.interest-blurb {
		width: 88%;
	}

	/* ----------------------------------------------*/
	/* 14.2 Couple Section							 */
	/* ----------------------------------------------*/
	.his-her-name .heart-wrapper {
		display: none;
	}

	/* ----------------------------------------------*/
	/* 14.3 Our Story Section						 */
	/* ----------------------------------------------*/
	.story-row.row {
		display: block;
		padding: 20px 0;
	}

	.story-row.row [class*="col-"] {
		display: block;
		text-align: center;
	}

	.story-date-wrapper {
		margin-bottom: 30px;
	}

	.story-date {
		float: none;
		position: absolute;
		top: -70px;
		left: 0;
		right: 0;
		margin: auto;
	}

	.story-date .month-year {
		font-family: "Oswald", sans-serif;
		font-size: 16px;
		color: #fff;
	}

	.arrow-right {
		display: none;
		float: none;
	}

	.arrow-left {
		display: none;
		float: none;
	}

	.vertical-line {
		display: none;
	}

	/* ----------------------------------------------*/
	/* 14.4 Where When Section						 */
	/* ----------------------------------------------*/
	.border-column {
		border: none;
	}

	@media screen and (max-width: 991px) and (orientation: landscape) {
		/* ----------------------------------------------*/
		/* 02.2 Logo & Nav Position						 */
		/* ----------------------------------------------*/
		#nav-menu {
			height: "230px !important";
		}
	}

	@media screen and (max-width: 768px) and (orientation: landscape) {
		/* 06.5.6 Save The Date Title */
		/* ----------------------------------------------*/
		#save-the-date {
			padding-right: 40px;
			padding-top: 0;
			margin-bottom: 0px;
		}

		#save {
			font-size: 50px;
			line-height: 30px;
		}

		#the-date {
			font-size: 40px;
		}

		#date {
			font-size: 30px;
			line-height: 20px;
		}
	}

	@media screen and (max-width: 768px) {
		/*===============================================*/
		/* 11. FORM										 */
		/*===============================================*/
		form .btn {
			width: 100%;
			white-space: normal;
		}
	}

	@media screen and (max-width: 480px) {
		/*===============================================*/
		/* 01. GENERAL			 						 */
		/*===============================================
p{
	font-size:14px;
	line-height:24px;
}*/

		/* 06.5.6 Save The Date Title */
		/* ----------------------------------------------*/
		#save-the-date {
			padding-right: 40px;
			margin-bottom: 0px;
			padding-top: 0;
		}

		#save {
			font-size: 60px;
			line-height: 35px;
		}

		#the-date {
			font-size: 50px;
		}

		#date {
			font-size: 40px;
			line-height: 25px;
		}

		/* 07.2.2 Section Title Wrapper					 */
		/* ----------------------------------------------*/
		.section-title {
			margin-bottom: 50px;
		}

		/* 07.2.4 Title-Excerpt White Box				 */
		/* ----------------------------------------------*/
		.title-excerpt {
			padding: 20px;
		}

		/* 07.2.11 Banner Text				 			 */
		/* ----------------------------------------------*/
		.banner-text.small,
		.banner-text.medium,
		.banner-text.large {
			padding: 0;
		}

		/*-----------------------------------------------*/
		/* 07.2.13 Popup Template						 */
		/*-----------------------------------------------*/
		#popup-template #the-content {
			padding-right: 30px;
			padding-left: 30px;
			text-align: center;
		}

		/*===============================================*/
		/* 10 MASONRY GALLERY							 */
		/*===============================================*/
		.masonry-col,
		.grid-sizer {
			width: "100% !important";
			margin-bottom: "5% !important";
		}

		/* ----------------------------------------------*/
		/* 12.1 Couple Section							 */
		/* ----------------------------------------------*/
		#couple {
			padding: 60px 0 40px 0;
		}

		.couple-name {
			font-size: 36px;
			padding-top: "80px !important";
			line-height: 38px;
		}

		.couple-excerpt {
			margin-top: 20px;
		}

		/* ----------------------------------------------*/
		/* 12.2 Location & Countdown Section			 */
		/* ----------------------------------------------*/
		#location-countdown .image-divider.auto-height {
			padding: 60px 0;
		}

		#counting {
			font-size: 55px;
			line-height: 45px;
		}

		#down {
			font-size: 50px;
			line-height: 50px;
		}

		.simple-countdown .countdown-amount {
			font-size: 16px;
		}
		.simple-countdown .countdown-period {
			font-size: 16px;
		}

		/* ----------------------------------------------*/
		/* 12.3 The Wedding Section			 			 */
		/* ----------------------------------------------*/
		#the-wedding {
			padding: 60px 0 40px 0;
		}

		/* ----------------------------------------------*/
		/* 12.4 RSVP Section				 			 */
		/* ----------------------------------------------*/
		#rsvp {
			padding: 60px 0;
			background-color: #fff;
		}

		/*===============================================*/
		/* 13 LOCATION PAGE								 */
		/*===============================================*/
		.interest-icon {
			width: 20%;
		}

		.interest-blurb {
			width: 78%;
		}

		/*===============================================*/
		/* 14. ONEPAGE SECTION							 */
		/*===============================================*/

		/* ----------------------------------------------*/
		/* 14.3 Our Story Section						 */
		/* ----------------------------------------------*/
		#our-story {
			padding: 80px 0;
		}

		#end-of-story {
			margin-top: 50px;
		}

		/* ----------------------------------------------*/
		/* 14.4 Where When Section						 */
		/* ----------------------------------------------*/
		#where-when {
			padding: 80px 0;
		}

		/* ----------------------------------------------*/
		/* 14.5 Gallery Section							 */
		/* ----------------------------------------------*/
		#gallery-section {
			padding: 80px 0;
		}

		/* ----------------------------------------------*/
		/* 14.6 Don't Miss It / Countdown Section		 */
		/* ----------------------------------------------*/

		.circle-countdown .countdown-amount {
			width: 45px;
			height: 45px;
			padding-top: 9px;
			font-size: 20px;
		}

		.circle-countdown .countdown-period {
			padding: 10px;
			display: block;
			text-align: center;
			font-size: 12px;
			text-transform: uppercase;
		}

		/* ----------------------------------------------*/
		/* 14.7 More Events Section						 */
		/* ----------------------------------------------*/
		#more-events {
			padding: 80px 0;
		}

		/* ----------------------------------------------*/
		/* 14.8 Groomsmen Section						 */
		/* ----------------------------------------------*/
		#groomsmen-section {
			padding: 80px 0;
		}

		/* ----------------------------------------------*/
		/* 14.9 Bridesmaid Section						 */
		/* ----------------------------------------------*/
		#bridesmaid-section {
			padding: 80px 0;
		}

		/* ----------------------------------------------*/
		/* 14.10 Gift Section							 */
		/* ----------------------------------------------*/
		#gift-section {
			padding: 80px 0;
		}

		/* ----------------------------------------------*/
		/* 14.11 RSVP Section							 */
		/* ----------------------------------------------*/
		#rsvp-section {
			padding: 80px 0;
		}
	}

	@media screen and (max-width: 320px) {
		/* 06.5.6 Save The Date Title */
		/* ----------------------------------------------*/
		#save-the-date {
			padding-right: 40px;
			padding-top: 0;
			margin-bottom: 0px;
		}

		#save {
			font-size: 50px;
			line-height: 30px;
		}

		#the-date {
			font-size: 40px;
		}

		#date {
			font-size: 30px;
			line-height: 20px;
		}

		/* ----------------------------------------------*/
		/* 12.1 Couple Section							 */
		/* ----------------------------------------------*/
		.couple-name {
			font-size: 30px;
			padding-top: "70px !important";
			line-height: 30px;
		}

		.couple-excerpt {
			margin-top: 20px;
		}

		/* ----------------------------------------------*/
		/* 12.5 Footer Section				 			 */
		/* ----------------------------------------------*/
		footer .image-divider.fixed-height {
			height: 400px;
		}

		#thank-you {
			text-align: center;
			color: #fff;
			font-family: "Pacifico";
			-ms-transform: rotate(-10deg);
			-webkit-transform: rotate(-10deg);
			transform: rotate(-10deg);
			padding-right: 30px;
			margin-top: 40px;
			margin-bottom: 25px;
		}

		#thank {
			font-size: 45px;
			line-height: 35px;
		}

		#you {
			font-size: 45px;
			line-height: 35px;
		}

		#footer-couple-name {
			font-family: "Oswald", sans-serif;
			font-size: 15px;
			color: #fff;
			letter-spacing: 3px;
			margin-top: 10px;
		}
	}
}
