@media screen and (max-width: 600px) {
  .memoriesSpacing {
    padding-top: 25%;
  }
}

@media screen and (min-width: 600px) and (max-width: 1200px) {
  .memoriesSpacing {
    padding-top: 15%;
  }
}

@media screen and (min-width: 1201px) {
  .memoriesSpacing {
    padding-top: 12%;
  }
}
