@media screen and (max-width: 600px) {
    .weddingPartyHeaderSpacing {
      padding-top: 25%;
    }
  }
  
  @media screen and (min-width: 600px) and (max-width: 1200px) {
    .weddingPartyHeaderSpacing {
      padding-top: 15%;
    }
  }
  
  @media screen and (min-width: 1201px) {
    .weddingPartyHeaderSpacing {
      padding-top: 12%;
    }
  }
  